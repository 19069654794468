





























































































































import Vue from 'vue';
import { getApps, getUserInfo } from '@/api/user';
import { sysLogResourceV1 } from '@/api/log';
import moment from 'moment';
export default Vue.extend({
  name: 'userManageDetails',
  data() {
    return {
      pagination: {
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      page: 1,
      limit: 10,
      loginInfoColumns: [
        {
          title: '登录时间',
          dataIndex: 'loginTime',
        },
        {
          title: '登录应用',
          dataIndex: 'loginApp',
          scopedSlots: { customRender: 'loginApp' },
        },
        {
          title: 'IP地址',
          dataIndex: 'ipAddress',
        },
        {
          title: '是否成功',
          dataIndex: 'loginStatus',
        },
      ],
      loginInfoData: [],
      userInfo: {} as any,
      basicInfo: {} as any,
    };
  },
  async created() {
    const { id = '', agencyCode = '' } = this.$route.query;
    if (!id) {
      return;
    }
    const { result } = await getUserInfo(id as string);
    this.userInfo = result || {};
    const apps = await getApps(agencyCode as string);
    this.basicInfo = {
      ...this.userInfo,
      lastLoginTime: this.dateTimeFormat(this.userInfo.lastLoginTime),
      recDate: this.dateFormat(this.userInfo.recDate),
      appInfo: apps.result
        ?.filter((item) => item.purchased && !item.validStatus)
        .map((item) => ({
          name: item.applicationName,
          imgUrl: item.applicationLogo,
        })),
    };
    this.fetchData();
  },
  methods: {
    dateFormat(date) {
      if (!date) {
        return null;
      }
      return moment(+date).format('YYYY-MM-DD');
    },
    dateTimeFormat(dateTime) {
      if (!dateTime) {
        return null;
      }
      return moment(+dateTime).format('YYYY-MM-DD HH:mm:ss');
    },
    fetchData() {
      sysLogResourceV1({
        logType: 0,
        openId: this.userInfo.openId,
        page: this.page,
        limit: this.limit,
      }).then((res) => {
        const { list, total } = res.result;
        this.loginInfoData = list?.map((item) => ({
          ...item,
          key: item.createDate,
          loginTime: item.createDate,
          loginApp: item.applicationName,
          ipAddress: item.ip,
          loginStatus: item.message,
        }));
        this.pagination.total = total;
      });
    },
    handleChange({ current, pageSize }) {
      this.page = current;
      this.limit = pageSize;
      this.fetchData();
    },
  },
});
