/*
 * @Author: zhangguoliang
 * @Date: 2021-12-09 09:42:42
 * @LastEditors: wuxinxin
 * @LastEditTime: 2022-05-20 17:23:19
 */
import request from '@/utils/request';
import { BaseResponse, Query } from '@/interface';

export function getUserList(params: Query): Promise<BaseResponse> {
  return request({
    url: `/passport-resources/1/passport/pageByPlatform`,
    method: 'GET',
    params,
  });
}

export function getUserInfo(openId: string): Promise<BaseResponse> {
  return request({
    url: `/passport-resources/1/passport/userDetail?openId=${openId}`,
    method: 'GET',
  });
}

export function getApps(agencyCode: string): Promise<BaseResponse> {
  return request({
    url: `/product-resource/1/application/agency?agencyCode=${agencyCode}`,
    method: 'get',
  });
}

export function usersNumber(): Promise<BaseResponse> {
  return request({
    url: `/passport-resources/1/passport/userCount`,
    method: 'GET',
  });
}
