var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"details"},[_c('div',{staticClass:"tenantInfoWrap"},[_c('img',{attrs:{"width":"70","height":"70","alt":"logo","src":"https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"}}),_c('div',{staticClass:"contentWrap"},[_c('a-row',[_c('a-col',{attrs:{"span":24}},[_vm._v(" 账号： "),_c('span',{staticClass:"key"},[_vm._v(_vm._s(_vm.userInfo.userCode))]),_c('span',[_vm._v("(ID:"+_vm._s(_vm.userInfo.openId)+")")])])],1),_c('a-row',[_c('a-col',{attrs:{"span":24}},[_vm._v(" 姓名： "),_c('span',{staticClass:"key"},[_vm._v(_vm._s(_vm.userInfo.realName))]),_vm._v(" 手机： "),_c('span',{staticClass:"key"},[_vm._v(_vm._s(_vm.userInfo.mobile))])])],1)],1)]),_c('div',[_c('a-tabs',{attrs:{"default-active-key":"1"}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"基本信息"}},[_c('div',{staticClass:"basicInfoWrap"},[_c('div',{staticClass:"basicInfoHeader"},[_c('span',{staticClass:"basicInfoHeaderTitle"},[_vm._v("基础信息")])]),_c('div',{staticClass:"basicInfoContent"},[_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('span',{staticClass:"label"},[_vm._v("用户ID：")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.basicInfo.openId))])]),_c('a-col',{attrs:{"span":8}},[_c('span',{staticClass:"label"},[_vm._v("创建时间：")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.basicInfo.recDate))])]),_c('a-col',{attrs:{"span":8}},[_c('span',{staticClass:"label"},[_vm._v("最后登录时间：")]),_c('span',{staticClass:"content"},[_vm._v(_vm._s(_vm.basicInfo.lastLoginTime))])])],1),_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('div',{staticClass:"appInfoWrap"},[_c('span',{staticClass:"label",staticStyle:{"width":"130px"}},[_vm._v("已授权应用：")]),_c('a-list',{staticStyle:{"width":"100%"},attrs:{"grid":{
                      gutter: 16,
                      xs: 1,
                      sm: 2,
                      md: 4,
                      lg: 4,
                      xl: 6,
                      xxl: 8,
                    },"data-source":_vm.basicInfo.appInfo},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{},[_c('div',{staticClass:"appItemWrap"},[_c('img',{staticClass:"imageStyle",attrs:{"width":"50","height":"50","alt":"应用图标","src":item.imgUrl}}),_c('div',{staticClass:"label"},[_vm._v(_vm._s(item.name))])])])}}])})],1)])],1)],1)]),_c('div',{staticClass:"basicInfoWrap"},[_c('div',{staticClass:"basicInfoHeader"},[_c('span',{staticClass:"basicInfoHeaderTitle"},[_vm._v("登录信息")])]),_c('div',{staticClass:"basicInfoContent"},[_c('a-table',{attrs:{"columns":_vm.loginInfoColumns,"data-source":_vm.loginInfoData,"pagination":_vm.pagination},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"loginApp",fn:function(text){return _c('div',{},[(text === 'PORTAL' || text === 'CONSOLE')?_c('a-avatar',{attrs:{"shape":"square","src":require('@/assets/img/yunsuanfang.png')}}):_vm._e(),(text === 'MANAGE')?_c('a-avatar',{style:({ backgroundColor: '#293845' }),attrs:{"shape":"square","src":require('@/assets/img/logo-hsjt-notext.png')}}):_vm._e(),_c('span',{staticClass:"loginAppStyle"},[_vm._v(_vm._s(text))])],1)}}])})],1)])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }